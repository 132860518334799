import React from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import type { UserType } from "@lesmills/gatsby-theme-common"

import {
  renderSEO,
  Layout,
  PublicRoute,
  ROUTES,
  isBrowser,
  LoadingIndicator,
} from "@lesmills/gatsby-theme-common"

const FAQ = React.lazy(() => import("../components/FAQ"))

type Props = {|
  pageContext: {
    lang: string,
  },
  data: Object,
|}

const FAQPage = (props: Props) => {
  const { pageContext, data } = props

  if (!data) return null

  const lang = pageContext.unPublishedLang || pageContext.lang
  const { prismicLayout = {}, prismicFaqPage = {} } = data
  const pageContent = prismicFaqPage.data || {}

  const renderFAQPage = ({ user, checkingSession }: UserType) => (
    <Layout
      data={prismicLayout.data}
      lang={lang}
      user={user}
      isLoading={checkingSession}
    >
      {renderSEO(pageContent, ROUTES(lang).FAQ, lang)}
      {isBrowser && (
        <React.Suspense fallback={<LoadingIndicator />}>
          <FAQ data={pageContent} lang={lang} />
        </React.Suspense>
      )}
    </Layout>
  )

  return <PublicRoute component={renderFAQPage} {...props} lang={lang} />
}

export const query = graphql`
  query($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      ...LayoutPublicPage
    }
    prismicFaqPage(lang: { eq: $lang }) {
      data {
        title {
          text
        }
        back_to_top {
          text
        }
        contact_us {
          text
        }
        find_your_question {
          raw
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        body {
          ... on PrismicFaqPageBodyFaqs {
            primary {
              id {
                text
              }
              title {
                raw
                text
              }
            }
            items {
              answers {
                raw
              }
              start_trial {
                raw
              }
              question {
                raw
              }
            }
          }
        }
      }
    }
  }
`

export default withPreview(FAQPage)
